import { doc, collection } from 'firebase/firestore';
import { db } from '../utils';

export function AssessmentVersionsRef(assessmentVersion) {
  return doc(db, 'assessmentVersions', assessmentVersion);
}

export function AssessmentRef(assessmentID) {
  return doc(db, 'assessments', assessmentID);
}

export function AssessmentActivityLogsRef(assessmentID) {
  return doc(db, 'logs/assessmentActivity/logs', assessmentID);
}

export function TestAssessmentRef() {
  return doc(db, 'testAssessment', 'test');
}

export function ScoreLogsRef() {
  return collection(db, 'logs/scoreAssessment/errors');
}

export function SyncingWithScorabilityLogsRef() {
  return collection(db, 'logs/assessmentsSentToScorability/errors');
}

import { BrowserRouter, Route, Routes as ReactRoutes } from 'react-router-dom';
import Header from '../components/header';
import Assessments from '../components/assessments';
import Error from '../components/util/error';
import Footer from '../components/footer';
import AssessmentStatus from '../components/status';
import NetworkStatus from '../components/networkStatus';

function Routes() {
  return (
    <>
      <div className="main-container">
        <BrowserRouter>
          <Header />
          <ReactRoutes>
            <Route path="/assessment" element={<Assessments />} />
            <Route path="/assessmentStatus" element={<AssessmentStatus />} />
            <Route path="/networkStatus" element={<NetworkStatus />} />
            <Route path="*" element={<Error header={''} message={`This page doesn't seem to exist yet...`} />} />
          </ReactRoutes>
        </BrowserRouter>
      </div>
      <Footer />
    </>
  );
}

export default Routes;

import React, { useState, useEffect } from 'react';
import { useScorabilitySlugVerificationAndCustomToken, useScoreErrors, useSendScoresToScorabilityErrors } from '../../hooks';
import _ from 'lodash';
import { AssessmentVersionsRef, TestAssessmentRef } from '../../utils/refs';
import { getCurrentUserId } from '../../utils';
import { getDoc, setDoc } from 'firebase/firestore';
import { getAuth, signInWithCustomToken } from 'firebase/auth';

function NetworkStatus() {
  /*
    All tests include:

    Ability to make network request to backend using http request (with ping) - could indicate issues with connectivity or our servers
    Ability to fetch document from Google Firestore - likely indicates issue with antivirus preventing network request due to Google Firestore request being classified as untrusted

  */

  const [testStatuses, setTestStatuses] = useState({
    authenticate: undefined,
    dbread: undefined,
    dbwrite: undefined,
    scoreErrors: 0,
    syncingErrors: 0
  });

  const { data: customToken, isLoading: customTokenLoading } = useScorabilitySlugVerificationAndCustomToken('test');
  const { data: scoreErrors, isLoading: scoreErrorsLoading } = useScoreErrors();
  const { data: sendScoresToScorabilityErrors, isLoading: sendScoresToScorabilityErrorsLoading } = useSendScoresToScorabilityErrors();

  useEffect(() => {
    if (!customTokenLoading) {
      canAuthenticateUser();
    }
  }, [customTokenLoading]);

  useEffect(() => {
    if (!scoreErrorsLoading) {
      setTestStatuses((prev) => ({
        ...prev,
        scoreErrors: scoreErrors
      }));
    }
  }, [scoreErrorsLoading]);

  useEffect(() => {
    if (!sendScoresToScorabilityErrorsLoading) {
      setTestStatuses((prev) => ({
        ...prev,
        syncingErrors: sendScoresToScorabilityErrors
      }));
    }
  }, [sendScoresToScorabilityErrorsLoading]);

  const canAuthenticateUser = async () => {
    // call useScorabilitySlugVerificationAndCustomToken
    if (customToken) {
      // sign in with custom token
      const auth = getAuth();

      signInWithCustomToken(auth, customToken)
        .then(async (userCredential) => {
          console.log('signed in with custom token', userCredential);
          setTestStatuses((prev) => ({
            ...prev,
            authenticate: true
          }));
          await canReadFromDatabase();
          await canWriteToDatabase();
        })
        .catch((error) => {
          console.log('Could not sign in with custom token', error);
          setTestStatuses((prev) => ({
            ...prev,
            authenticate: false
          }));
        });
    } else {
      setTestStatuses((prev) => ({
        ...prev,
        authenticate: false
      }));
    }
  };

  const canReadFromDatabase = async () => {
    const assessmentVersionId = '1.0';
    const assessmentQuestionsDoc = AssessmentVersionsRef(assessmentVersionId);

    try {
      const doc = await getDoc(assessmentQuestionsDoc);
      const data = doc.data();
      if (data) {
        setTestStatuses((prev) => ({
          ...prev,
          dbread: true
        }));
      } else {
        setTestStatuses((prev) => ({
          ...prev,
          dbread: false
        }));
      }
    } catch (error) {
      console.log('error', error);
      setTestStatuses((prev) => ({
        ...prev,
        dbread: false
      }));
    }
  };

  const canWriteToDatabase = async () => {
    // try to write to a test collection
    // if successful, set testStatuses.dbwrite to true
    // if unsuccessful, set testStatuses.dbwrite to false
    const assessmentTestRefDoc = TestAssessmentRef();

    try {
      await setDoc(assessmentTestRefDoc, {
        test: 'test'
      });

      setTestStatuses((prev) => ({
        ...prev,
        dbwrite: true
      }));
    } catch (error) {
      console.log('error', error);
      setTestStatuses((prev) => ({
        ...prev,
        dbwrite: false
      }));
    }
  };

  const isUndefined = testStatuses.authenticate === undefined || testStatuses.dbread === undefined || testStatuses.dbwrite === undefined;

  return (
    <>
      <h1>Network Status</h1>
      {scoreErrorsLoading || sendScoresToScorabilityErrorsLoading || customTokenLoading || isUndefined ? (
        <div>Loading...</div>
      ) : (
        <React.Fragment>
          <div>{`Authenticate User: ${testStatuses?.authenticate?.toString()}`}</div>
          <div>{`Assessment Loads: ${testStatuses?.dbread?.toString()}`}</div>
          <div>{`Assessment Can be Completed: ${testStatuses?.dbwrite?.toString()}`}</div>
          <div>{`Scoring Errors: ${testStatuses?.scoreErrors?.toString()} error(s)`}</div>
          <div>{`Syncing Errors: ${testStatuses?.syncingErrors?.toString()} error(s)`}</div>
        </React.Fragment>
      )}
    </>
  );
}

export default NetworkStatus;
